import React, { createContext, useContext } from "react";
import { ethers, Contract } from "ethers";
import { useConnect } from "./ConnectContext";
import deploymentsArtifacts from "../hardhat/artifacts.json";

import Debug from "debug";
const debug = Debug("web:contract");

export enum ContractName {
  Recruitment = "Recruitment",
  StakeToken = "StakeToken",
  Hero = "Hero",
  NFTStaking = "Outskirt",
  TTK = "TTK",
  TTKLP = "TTK-LP",
  TTKLPStaking = "TTKLPStaking",
  Fusion = "Fusion",
  Chi = "CHI",
}
export type ContractsProps = { [key in ContractName]?: Contract };

export const ContractsContext = createContext<ContractsProps>({});

export const useContracts = () => {
  const context = useContext(ContractsContext);
  return context;
};

export const ContractsProvider: React.FC = ({ children }) => {
  const { signer } = useConnect();

  const getContract = React.useCallback(
    (name: string) => {
      if (signer) {
        const deploymentChainId = process.env.REACT_APP_DEPOLYMENT_CHAIN_ID;
        let defaultArtifact = Object.values(
          deploymentsArtifacts[deploymentChainId]
        )[0];
        if (!defaultArtifact) return null;
        const contractArtifact = defaultArtifact["contracts"][name];

        if (!contractArtifact) {
          debug(name, "artifact not found");
          return null;
        }

        let address = contractArtifact.address
        if (process.env[`REACT_APP_CONTRACT_${name.toString().toUpperCase()}`]) {
          address = process.env[`REACT_APP_CONTRACT_${name.toString().toUpperCase()}`];
        }
        debug(name, address);
        return new ethers.Contract(
          address,
          contractArtifact.abi,
          signer
        );
      }
      return null;
    },
    [signer]
  );

  const value = React.useMemo(() => {
    const recruitment = getContract(ContractName.Recruitment);
    const stakeToken = getContract(ContractName.StakeToken);
    const hero = getContract(ContractName.Hero);
    const nftStaking = getContract(ContractName.NFTStaking);
    const chi = getContract(ContractName.Chi);
    const fusion = getContract(ContractName.Fusion);
    return {
      [ContractName.Recruitment]: recruitment,
      [ContractName.StakeToken]: stakeToken,
      [ContractName.Hero]: hero,
      [ContractName.NFTStaking]: nftStaking,
      [ContractName.Fusion]: fusion,
      [ContractName.Chi]: chi,
    };
  }, [getContract]);

  return (
    <ContractsContext.Provider value={value}>
      {children}
    </ContractsContext.Provider>
  );
};
