const fusion = {
  instructions: "Fusion subtitle",
  clear_selection: "Clear selection",
  no_selected: "No Cards Selected",
  "steps.1.title": "Select 2 - 5 Heroes for fusion",
  "steps.2.title": "These are the Heroes selected for fusion",
  "steps.3.title": "Commence fusion with these Heroes",
  "steps.4.title": "Congratulations on your new Hero",
  "steps.1.label": "Select Heroes",
  "steps.2.label": "Approval",
  "steps.3.label": "Confirmation",
  "steps.4.label": "Fusion Result",
  "guideline.title": "Fusion Guideline",
  "guideline.1":
    "Every additional Hero from the same Kingdom will gain 20% chance to fuse a hero from that Kingdom.",
  "guideline.2":
    "Example: 5x 1 Star from Wu, will guarantee a 2 Star hero from Wu",
  "guideline.3":
    "For details please refer to <1>{{link}}</1> for additional formulas and further information.",
  "error.insufficient_balance": "Insufficient CHI Balance",
  "result.1": "You're almost set. Fusing in progress...",
  "result.2": "Congratulations on your new Hero",
  "result.summary.title": "Summary",
  "result.summary.hero_fused": "Hero Fused",
  "result.summary.transaction": "Transaction",
  probability: "Chance of Upgrade",
};
export default fusion;
