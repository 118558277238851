const staking = {
  "field.tvl.label": "Total Value Locked",
  "field.available_to_claim.label": "Available to Claim",
  "field.staked.label": "Staked",
  "action.stake.default": "Stake",
  "action.stake.approve": "Approving",
  "action.stake.deposit": "Depositing",
  "action.stake.update": "Please wait",
  "action.unstake.default": "Unstake",
  "action.unstake.withdraw": "Unstaking",
  "action.unstake.update": "Please wait",
  "action.harvest.default": "Harvest",
  "action.harvest.withdraw": "Harvesting",
  "action.harvest.update": "Please wait",
  "form.stake.amount": "Enter Amount",
  "form.stake.confirm": "Confirm Staking",
  "stake.completed": "Successfully staked {{amount}} {{unit}}. Please wait.",
  "unstake.completed": "Successfully unstaked!",
  "harvest.completed": "Successfully harvesetd!",
  "chain.switch": "Connect to start staking",
};
export default staking;
