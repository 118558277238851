import { Kingdom, User } from "@data/types";
import { providers, Signer } from "ethers";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import Web3Modal, { IProviderOptions } from "web3modal";
import useConnectUser from "../data/mutations/useConnectUser";
import {
  signOut,
  signInWithCustomToken,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase";
import { useErrorNotification, ErrorCode } from "./ErrorNotificationContext";
import Debug from "debug";

export type Address = `0x${string}`;
const debug = Debug("web:connect");

const providerOptions: IProviderOptions = {};

const getTimestampedSignature = async ({
  chainId,
  address,
}: {
  chainId: number;
  address: Address;
}) => {
  const timestamp = new Date().toUTCString();
  const message = `Connecting to the Three Kingdoms at ${timestamp}`;

  // Sign
  const msgParams = JSON.stringify({
    types: {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      Mail: [{ name: "content", type: "string" }],
    },
    domain: {
      name: "TTK",
      version: "1",
      chainId,
      verifyingContract: "0x0000000000000000000000000000000000000000",
    },
    primaryType: "Mail",
    message: { content: message },
  });

  const signature = await window.ethereum.request({
    method: "eth_signTypedData_v4",
    params: [address, msgParams],
    from: address,
  });
  return {
    signature,
    timestamp,
  };
};

export interface ConnectProps {
  web3Modal?: Web3Modal;
  provider?: providers.Web3Provider;
  signer?: Signer;
  block?: number;
  address?: Address;
  chainId?: number;
  isConnected: boolean;
  connect?: () => Promise<void>;
  disconnect?: () => Promise<void>;
  connectUserAccount?: (kingdom: Kingdom) => void;
  user?: User;
  isLoading?: boolean;
  isChainIdCorrect?: boolean;
  switchChain?: () => void;
}

export const ConnectContext = createContext<ConnectProps>({
  isConnected: false,
});

export const useConnect = () => {
  const context = useContext(ConnectContext);

  return context;
};

const getHexademicalChainId = (chainId: number) => `0x${chainId.toString(16)}`;
const defaultDecimalChainId = parseInt(process.env.REACT_APP_CHAIN_ID || "0");

const isChainIdCorrect = (chainId: number) => {
  if (!defaultDecimalChainId) return true;
  return (
    getHexademicalChainId(chainId) ===
    getHexademicalChainId(defaultDecimalChainId)
  );
};

export const ConnectProvider: React.FC = ({ children }) => {
  const { addError } = useErrorNotification();
  const [web3Modal, setWeb3Modal] = useState<Web3Modal>();
  const [provider, setProvider] = useState<providers.Web3Provider>();
  const [signer, setSigner] = useState<Signer>();
  const [block, setBlock] = useState<number>();
  const [address, setAddress] = useState<Address>();
  const [isConnected, setIsConnected] = useState(false);
  const [chainId, setChainId] = useState<number>();
  const [isSigning, setIsSigning] = useState(false);
  const [timestampedSignature, setTimestampedSignature] =
    useState<{ timestamp: string; signature: string }>();
  const {
    login: {
      mutate,
      data: customToken,
      reset: resetLogin,
      isLoading: isLoadingLogin,
    },
    getUser: { refetch: getUser, data: user, isLoading: isLoadingUser },
  } = useConnectUser();

  const queryClient = useQueryClient();

  const disconnect = React.useCallback(async () => {
    try {
      await signOut(auth);
      web3Modal?.clearCachedProvider();
      setAddress!(undefined);
      setProvider!(undefined);
      setIsConnected!(false);
      setTimestampedSignature!(undefined);

      resetLogin();
      queryClient.invalidateQueries("user");
      queryClient.clear();
    } catch (e) {
      debug(e.message);
      addError({
        code: ErrorCode.GeneralError,
        values: {
          message: e.message,
        },
      });
    }
  }, [queryClient, resetLogin, web3Modal, addError]);

  const connect = React.useCallback(
    async (isRetainingSession: boolean = false) => {
      if (web3Modal) {
        try {
          const myModalProvider = await web3Modal.connect();
          const ethersProvider = new providers.Web3Provider(
            myModalProvider,
            "any"
          );
          const ethersSigner = ethersProvider.getSigner();
          const ethAddress = (await ethersSigner.getAddress()) as Address;
          const network = await ethersProvider.getNetwork();

          setSigner!(ethersSigner);
          setAddress!(ethAddress);
          setChainId!(network.chainId);
          setProvider!(ethersProvider);
          setIsConnected!(true);

          debug(`Connected to Chain: ${network.chainId}`);

          if (!isRetainingSession) {
            try {
              setIsSigning(true);
              const { signature, timestamp } = await getTimestampedSignature({
                chainId: network.chainId,
                address: ethAddress,
              });

              setIsSigning(false);
              setTimestampedSignature({
                signature,
                timestamp,
              });
              mutate({
                address: ethAddress,
                signature,
                timestamp,
              });
            } catch (e) {
              debug(e.message);
              addError({
                code: ErrorCode.GeneralError,
                values: {
                  message: e.message,
                },
              });
              disconnect();
            } finally {
              setIsSigning(false);
            }
          }
        } catch (ex) {}
      }
    },
    [web3Modal, mutate, addError, disconnect]
  );

  const switchChain = React.useCallback(async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: getHexademicalChainId(defaultDecimalChainId),
          },
        ],
      });
    } catch (error) {
      if (error.code === 4902) {
        // todo: wallet_addEthereumChain
      }
      return;
    }
  }, []);

  const connectUserAccount = React.useCallback(
    async (kingdom: Kingdom) => {
      try {
        mutate({
          address,
          kingdom,
          signature: timestampedSignature.signature,
          timestamp: timestampedSignature.timestamp,
        });
      } catch (e) {
        debug(e.message);
        addError({
          code: ErrorCode.GeneralError,
          values: {
            message: e.message,
          },
        });
      }
    },
    [address, mutate, addError, timestampedSignature]
  );
  const signIn = React.useCallback(
    async (token: string) => {
      try {
        await signInWithCustomToken(auth, token);
      } catch (e) {
        debug(e.message);
        addError({
          code: ErrorCode.GeneralError,
          values: {
            message: e.message,
          },
        });
      }
    },
    [addError]
  );

  useEffect(() => {
    if (customToken) {
      signIn(customToken);
    }
  }, [customToken, signIn]);

  useEffect(() => {
    const unlisten = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        debug("retaining user session");
        connect(true);
        getUser();
      }
    });
    return () => {
      unlisten();
    };
  }, [getUser, connect]);

  useEffect(() => {
    if (isConnected) {
      return;
    }

    const myWeb3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
    });

    setWeb3Modal(myWeb3Modal);
  }, [isConnected, setWeb3Modal]);

  useEffect(() => {
    if (!web3Modal || !web3Modal.cachedProvider) {
      return;
    }
  }, [web3Modal, setBlock, connect]);

  // listen new block
  useEffect(() => {
    if (!provider || !setBlock) {
      return undefined;
    }

    function handleNewBlock(blockNumber: number) {
      setBlock(blockNumber);
    }

    provider.on("block", handleNewBlock);

    return () => {
      provider.removeListener("block", handleNewBlock);
    };
  }, [provider, setBlock]);

  // event tracking
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        debug("provider: accounts changed");
        disconnect();
      });

      window.ethereum.on("chainChanged", (updatedChainId: string) => {
        debug("provider: chain changed", updatedChainId);
        // Only reconnecting if previous user session is found;
        // timestampedSignature can be null if session is retained via firebase
        if (!!timestampedSignature || !!user) {
          connect(true);
        }
      });

      window.ethereum.on("disconnect", (error) => {
        // Error Code 1013: Attempt to reconnect if attempting to reconnect
        // "Error: MetaMask: Disconnected from chain. Attempting to connect."
        if (error.code === 1013 && (!!timestampedSignature || !!user)) {
          debug("attempt to re-connect due to provider reconnection");
          connect(true);
        } else {
          debug("provider: disconnect");
          disconnect();
        }
      });
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeAllListeners();
      }
    };
  }, [connect, disconnect, timestampedSignature, user]);

  const isLoading = React.useMemo(
    () => isLoadingLogin || isLoadingUser || isSigning,
    [isLoadingLogin, isLoadingUser, isSigning]
  );
  return (
    <ConnectContext.Provider
      value={{
        web3Modal,
        address,
        block,
        isConnected,
        chainId,
        signer,
        provider,
        connect,
        disconnect,
        connectUserAccount,
        user,
        isLoading,
        switchChain,
        isChainIdCorrect: chainId && isChainIdCorrect(chainId),
      }}
    >
      {children}
    </ConnectContext.Provider>
  );
};
