const Path = {
  Home: {
    root: "/",
  },
  Outskirt: {
    root: "/outskirt",
  },
  Cities: {
    root: "/city",
  },
  Heroes: {
    root: "/hero",
    detail: "/hero/:heroId",
  },
  Battles: {
    root: "/battle",
    battlefield: "/battle/:battleId",
  },
  Fusion: {
    root: "/fusion",
  },
  Lottery: {
    root: "/lottery",
  },
};
export default Path;
