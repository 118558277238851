import {
  Box,
  Button,
  Popper as MuiPopper,
  alpha,
  tooltipClasses,
  Tooltip,
  styled,
} from "@mui/material";
import { NavLink, NavLinkProps } from "react-router-dom";
import Path from "@routes/path";
import { useConnect } from "../../contexts/ConnectContext";
import { useTranslation } from "react-i18next";
import useFeatures from "../../features";
import { routes } from "../../routes";

const ButtonBase = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.75, 3),
  letterSpacing: 0.5,
  margin: theme.spacing(0, 1),
  color: theme.palette.common.white,
  border: "1px solid transparent",
  background: "transparent",
  borderRadius: 32,
}));

const StyledButton = styled(ButtonBase)<NavLinkProps>(({ theme }) => ({
  "&:hover": {
    color: theme.palette.common.white,
    borderColor: "#fff",
    background: "rgba(255,255,255, 0.1)",
  },
  "&:active": {
    background: "rgba(255,255,255, 0.3)",
  },

  "&.Mui-selected": {
    padding: theme.spacing(0.75, 3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0.75, 7),
    },
    fontWeight: 700,
    color: theme.palette.common.white,
    background: "rgba(255,255,255, 0.3)",
  },
}));

const ComingSoonButton = styled(ButtonBase)(({ theme }) => ({
  cursor: "default",
  "&:hover, &:active": {
    borderColor: "transparent",
    background: "transparent",
  },
}));

const items = [
  {
    path: Path.Home.root,
    label: "common:nav.home",
    exact: true,
  },
  {
    label: "common:nav.outskirt",
    path: Path.Outskirt.root,
  },
  {
    label: "common:nav.battles",
  },
  {
    label: "common:nav.cities",
  },
  {
    path: Path.Heroes.root,
    label: "common:nav.heroes",
  },
  {
    label: "common:nav.fusion",
    path: Path.Fusion.root,
  },
  {
    path: Path.Lottery.root,
    label: "common:nav.lottery",
  },
];

const Popper = styled(MuiPopper)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: `1px solid ${alpha("#000", 0.5)}`,
    color: "#fff",
    fontSize: theme.typography.overline.fontSize,
    borderRadius: "2px",
    padding: theme.spacing(0.25, 1),
    margin: 0,
    fontFamily: "Abril Fatface",
    background: alpha("#000", 0.65),
  },
}));

const Menu = () => {
  const { user } = useConnect();
  const { t } = useTranslation();
  const features = useFeatures();

  if (!user) return null;
  return (
    <Box
      flex={1}
      display="flex"
      mb={4}
      sx={{
        overflow: {
          xs: "auto",
        },
        "::-webkit-scrollbar": {
          xs: {
            display: "none",
          },
        },
      }}
    >
      {user && (
        <>
          {items.map(({ path, label, exact }, idx) => {
            const flag = routes.find((p) => p.path === path)?.flag;
            return path && (!flag || features[flag]) ? (
              <StyledButton
                component={NavLink}
                to={path}
                exact={exact}
                key={idx}
                activeClassName="Mui-selected"
              >
                {t(label)}
              </StyledButton>
            ) : (
              <Tooltip
                title={t("common:coming_soon")}
                placement="bottom"
                key={idx}
                PopperComponent={Popper}
              >
                <ComingSoonButton sx={{ opacity: 0.5 }} key={idx}>
                  {t(label)}
                </ComingSoonButton>
              </Tooltip>
            );
          })}
        </>
      )}
    </Box>
  );
};
export default Menu;
