const outskirt = {
  title: "Outskirt",
  "explorer.select_hero.confirm": "Select Hero",
  "explorer.select_hero.title": "Select Hero",
  "explorer.title": "Explorer",
  "explorer.dispatch.loading": "Dispatching",
  "explorer.dispatch.confirm": "Explorer",
  "explorer.dispatch.success_message": "Successfully dispatched the explorer",
  "explorer.dispatch.success.to_hero_page": "Go to Heroes List",
  "explorer.error.insufficient_balance": "Insufficient Balance",
  "explorer.staking_guide.1": "Select {{nft}} and {{token}}",
  "explorer.re_select": "Select Again",
  "explorer.no_nft": "You can first recruit heroes and dispatch explorer!",

  "action.stake.default": "Stake",
  "action.stake.approve": "Approving",
  "action.stake.deposit": "Depositing",
  "action.stake.update": "Please wait",
  "action.unstake.default": "Unstake",
  "action.unstake.withdraw": "Unstaking",
  "action.unstake.update": "Please wait",
  "action.harvest.default": "Harvest",
  "action.harvest.withdraw": "Harvesting",
  "action.harvest.update": "Please wait",

  "success.stake.description": "Successfully Staked",
  "success.unstake.description": "Successfully Unstaked",
  "success.harvest.description": "Successfully Harvested",

  "explorer.reward.title": "Explorer Summary",
  "explorer.reward.unstaked": "{{name}} Unstaked",
  "explorer.reward.rewarded": "{{name}} Rewarded",

  "explorer.switch_chain": "Switch to BSC Chain and dispatch explorer!",
  "explorer.nft.max":
    "You can only harvest {{max}} {{nft}} at max. The amount will be rounded down to nearest integer.",
};
export default outskirt;
