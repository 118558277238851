import { initializeApp, getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAs-ah2H8ZdC_s2a-iTHaJE6w6mLHNQkOY",
  authDomain: "ttk-dev.firebaseapp.com",
  projectId: "ttk-dev",
  storageBucket: "ttk-dev.appspot.com",
  messagingSenderId: "683496225507",
  appId: "1:683496225507:web:40ee59e4462d3c3c7923d6",
  measurementId: "G-NR79SJ3M27"
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const functions = getFunctions(getApp());
export const db = getFirestore(getApp());
export const auth = getAuth(getApp());
export const analytics = getAnalytics(getApp());

if (process.env.REACT_APP_USE_EMULATOR) {
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
}
