const lottery = {
  "draw.title": "Commander, how much influence do you have?",
  "draw.confirm": "Confirm and Recruit",
  "draw.instructions": "The larger the army the more we can conquer",
  "draw.loading": "Please wait",
  "draw.card_list": "Check All Heroes",
  "draw.field.card_quantity": "Number of Heroes",
  "draw.field.bnb": "BNB Required",
  "draw.step.2.title": "Confirming Transaction",
  "draw.step.3.title": "These are your newly acquired Heros",
  "draw.step.3.subtitle":
    "It’s time to defeat other warlords and conquer the land",
  "draw.step.3.transaction.title": "Collecting the heroes",
  "draw.summary.card_quantity": "Heroes Recruited",
  "draw.summary.txn": "Transaction Hash",
  "draw.steps.1.label": "Confirm Number of Heroes",
  "draw.steps.2.label": "Confirming Transaction",
  "draw.steps.3.label": "Collect Your Heroes",
  "draw.wrong_chain": "Please switch to BSC Chain",
  "draw.draw_again": "Draw again",
  "draw.collecting.remarks":
    "Wait a moment. We are collecting the heroes for you.",
  "draw.failed_list_heroes.title": "Check out your Heroes",
  "draw.exceed_quantity": "You can only draw {{max}} heroes at once",
};

export default lottery;
