const user = {
  "connect_wallet.connect": "Connect",
  "connect_wallet.disconnect": "Disconnect",
  "connect_wallet.select_kingdom.title": "Your Kingdom Awaits You!",
  "connect_wallet.select_kingdom.remarks":
    "Choose your Kingdom, Prepare for War, Recruit your Heroes and Lead your Army to victory.",
  "connect_wallet.confirm_kingdom": "Confirm Kingdom",
};

export default user;
