import { Box, BoxProps } from "@mui/material";
import { Kingdom } from "@data/types";

const badgeIcon = {
  [Kingdom.WU]: "/images/kingdom/wu.png",
  [Kingdom.SHU]: "/images/kingdom/shu.png",
  [Kingdom.WEI]: "/images/kingdom/wei.png",
  [Kingdom.QUN]: "/images/kingdom/qun.png",
};
const sizeDimension = {
  sm: 40,
  md: 60,
  lg: 80,
  xl: 100,
};
type KingdomBadgeProps = {
  kingdom: Kingdom;
  size?: "sm" | "md" | "lg" | "xl";
} & BoxProps;

const KingdomBadge = ({
  kingdom,
  size = "sm",
  ...props
}: KingdomBadgeProps) => (
  <Box
    {...props}
    sx={{
      height: sizeDimension[size],
      width: sizeDimension[size],
      backgroundImage: `url("${badgeIcon[kingdom.toUpperCase()]}")`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      ...props.sx,
    }}
  />
);
export default KingdomBadge;
