import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import React, { Suspense } from "react";
import BaseTemplate from "../components/templates/Base";
import Path from "./path";
import { useConnect } from "../contexts/ConnectContext";
// import ComingSoon from "../pages/coming-soon";
import Loader from "@components/shared/Loader";

const Home = React.lazy(() => import("../pages/home"));
const Lotteries = React.lazy(() => import("../pages/lottery"));
const Heroes = React.lazy(() => import("../pages/heroes"));
const Fusion = React.lazy(() => import("../pages/fusion"));
// const Cities = React.lazy(() => import("../pages/cities"));
const Outskirt = React.lazy(() => import("../pages/outskirt"));
// const Battles = React.lazy(() => import("../pages/battles"));
// const BattleField = React.lazy(() => import("../pages/battles/battlefield"));

export const routes = [
  {
    path: Path.Home.root,
    exact: true,
    component: Home,
  },
  {
    path: Path.Cities.root,
    // component: Cities,
    isConnected: true,
  },
  {
    path: Path.Outskirt.root,
    component: Outskirt,
    isConnected: true,
    flag: "outskirt",
  },
  {
    path: Path.Battles.root,
    // component: Battles,
    isConnected: true,
    exact: true,
  },
  {
    path: Path.Battles.battlefield,
    // component: BattleField,
    isConnected: true,
  },
  {
    path: Path.Heroes.root,
    component: Heroes,
    exact: true,
    isConnected: true,
  },
  {
    path: Path.Fusion.root,
    component: Fusion,
    isConnected: true,
  },
  {
    path: Path.Lottery.root,
    component: Lotteries,
    isConnected: true,
  },
];

const Routes = () => {
  const { user } = useConnect();
  const { pathname } = useLocation();
  const videoType = React.useMemo(() => {
    if (pathname !== "/") {
      return "fire";
    }
    return user ? "fire" : "trailer";
  }, [pathname, user]);

  return (
    <Suspense
      fallback={
        <BaseTemplate>
          <Loader open={true} />
        </BaseTemplate>
      }
    >
      <BaseTemplate videoType={videoType}>
        <Switch>
          {routes.map((route, index) =>
            route.component ? (
              !route.isConnected || (route.isConnected && user) ? (
                <Route key={index} {...route} />
              ) : (
                <Redirect push key={index} from={route.path} to="/" />
              )
            ) : null
          )}
          <Redirect to="/" />
        </Switch>
      </BaseTemplate>
    </Suspense>
  );
};
export default Routes;
