export enum Kingdom {
  WEI = "WEI",
  SHU = "SHU",
  WU = "WU",
  QUN = "QUN",
}
export type User = {
  address: string;
  kingdom: Kingdom;
};

export type Campaign = {};

/** Hero */
export type Hero = {
  id: string;
  tokenId: string;
  block: number;
  template: HeroTemplate;
  city?: City;
  owner: User;
  status: HeroStatus;
};
export enum HeroStatus {
  Idle = "idle",
  Alive = "alive",
  Prisoned = "prisoned",
  Dead = "dead",
  Outskirt = "outskirt",
  InBattle = "in-battle",
}
export type HeroTemplate = {
  level: number;
  leadership: number;
  attack: number;
  defence: number;
  intelligence: number;
  luck: number;
  energy: number;
  description: string;
  image: string;
  external_url: string;
  campaign: Campaign;
  name: string;
  gender: string;
  nameZh: string;
  kingdom: Kingdom;
};
/** City */
export type City = {
  id: string;
  name: string;
  heroes: Hero[];
  owner?: User | null;
};

/** Battle */
export enum BattleStatus {
  OnGoing = "on-going",
  Finished = "finished",
}

export type Battle = {
  id: string;
  city: City;
  participants: BattleParticipant[];
  latestLog: BattleLog | null;
  battleLogs: BattleLog[];
  createdAt: string;
};

export type BattleLog = {
  id: string;
  isCompleted: boolean;
  createdAt: string;
  participants: BattleParticipant[];
};

export enum BattleParticipantStatus {
  InBattle = "in-battle",
  Captured = "captured",
  Fallen = "fallen",
  Retreated = "retreated",
}

export type BattleParticipant = {
  id: string;
  hero: Hero;
  isOffending: boolean;
  isReinforcement: boolean;
  ttk: number;
  energy: number;
  status: BattleParticipantStatus;
  battleLog: BattleLog;
};

export interface ExecutedHero extends Hero {
  ttk: number;
}

export enum StakingPool {
  TtkLp = "TTK-LP",
}
