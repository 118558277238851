import { auth } from "../firebase";

export const getAuthRequestHeader = async () => {
  const user = auth.currentUser;
  const token = await user?.getIdToken();
  return {
    headers: {
      Authorization: token && `Bearer ${token}`,
    },
  };
};
