const common = {
  cancel: "Cancel",
  ttk: "TTK",
  energy: "Energy",
  close: "Close",
  back: "Back",
  coming_soon: "Coming Soon",
  nav: {
    home: "Home",
    outskirt: "Outskirt",
    battles: "Battles",
    cities: "Cities",
    heroes: "Heroes",
    fusion: "Fusion",
    lottery: "Recruit",
  },
  switch: "Switch Chain",
};
export default common;
