const hero = {
  "meta.status": "Status",
  "meta.attack": "Attack",
  "meta.defence": "Defence",
  "meta.description": "Description",
  "meta.energy": "Energy",
  "meta.intelligence": "Intelligence",
  "meta.leadership": "Leadership",
  "meta.luck": "Luck",
  "meta.level": "Level",

  "list_heroes.title": "Commander this is your current army.",
  "list_heroes.description":
    "Commander we must continue to mass our strength and army to unite the Kingdom.",
  "list_heroes.summary": "You have {{count}} heroes",
  "list_heroes.empty.title":
    "Commanders, it’s time for you to recruit your desired talents to form your own history in the land of The Three Kingdoms!",
  "list_heroes.empty.description":
    "With the country’s fate placed in your hands, it is up to you to gather the best heroes across the three kingdoms and change the course of history. Can you recruit the charismatic Cao Cao, the noble Liu Bei or the capable General Sun Quan to join your alliance and unite the kingdom?",
  "list_heroes.empty.cta": "Recruit",
  "list_heroes.page": "Total: {{total}} - Page {{page}} / {{maxPage}} ",
};
export default hero;
