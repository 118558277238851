import { Box } from "@mui/material";
import { User } from "@data/types";
import KingdomBadge from "./KingdomBadge";

const Address = ({ user }: { user: User }) => {
  return (
    <Box
      sx={{
        width: 200,
        height: 70,
        display: "flex",
        alignItems: "center",
        backgroundImage: `url("./images/brush-1.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
      }}
    >
      <Box>
        <KingdomBadge kingdom={user.kingdom} />
      </Box>
      <Box
        sx={{
          p: 1,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontSize: 12,
        }}
      >
        {user.address}
      </Box>
    </Box>
  );
};
export default Address;
