import { QueryClientProvider } from "react-query";
import queryClient from "./data/queryClient";
import { ConnectProvider } from "./contexts/ConnectContext";
import { ErrorNotificationProvider } from "./contexts/ErrorNotificationContext";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import Routes from "./routes";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { ContractsProvider } from "@contexts/ContractsContext";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorNotificationProvider>
        <QueryClientProvider client={queryClient}>
          <ConnectProvider>
            <ContractsProvider>
              <Router>
                <Routes />
              </Router>
            </ContractsProvider>
          </ConnectProvider>
        </QueryClientProvider>
      </ErrorNotificationProvider>
    </ThemeProvider>
  );
}

export default App;
