import { Kingdom } from "@data/types";

export const ORANGE = "#ffb313";
export const BROWN = "#9B8060";
export const JUNGLE_GREEN = "#21312F";
export const TURQUOISE = "#78A2AA";
export const BRIGHT_BLUE = "#073ca1";
export const KHAKI = "#858458";
export const DARK_BROWN = "#3e1103";
export const GRAY = "#666";

export const KINGDOM_WU = "rgba(150,30,30)";
export const KINGDOM_SHU = "rgba(30,150,30)";
export const KINGDOM_WEI = "rgba(30,30,150)";

const kingdomColor = {
  [Kingdom.WU]: KINGDOM_WU,
  [Kingdom.SHU]: KINGDOM_SHU,
  [Kingdom.WEI]: KINGDOM_WEI,
};
export const getKingdomColor = (kingdom: Kingdom) => {
  return kingdomColor[kingdom];
};
