import axios, { AxiosRequestConfig } from "axios";

const defaultBaseURL = process.env.BASE_URL || "/api";

const useAxios = (baseURL: string = defaultBaseURL) => {
  const instance = axios.create({
    baseURL,
  });
  return {
    get: <T,>(url: string, config?: AxiosRequestConfig) =>
      instance.get<T>(url, config),
    post: <T,>(url: string, data?: any, config?: AxiosRequestConfig) =>
      instance.post<T>(url, data, config),
  };
};
export default useAxios;
