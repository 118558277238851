import { useMutation, useQuery } from "react-query";
import useAxios from "../useAxios";
import { Kingdom, User } from "../types";
import { getAuthRequestHeader } from "../auth";

const useConnectUser = () => {
  // Todo: from env
  const { post, get } = useAxios(process.env.REACT_APP_API_URL);
  const mutation = useMutation(
    async (userPayload: {
      address: string;
      kingdom?: Kingdom;
      signature: string;
      timestamp: string;
    }) => {
      const { data } = await post<string>("/user", userPayload);
      return data;
    }
  );

  const getUser = useQuery<User, Error>(
    "user",
    async () => {
      const headers = await getAuthRequestHeader();
      const { data } = await get<User>("/user", headers);
      return data;
    },
    { enabled: false }
  );

  return {
    login: mutation,
    getUser: getUser,
  };
};
export default useConnectUser;
