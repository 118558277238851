import user from "./user";
import common from "./common";
import battle from "./battle";
import city from "./city";
import hero from "./hero";
import outskirt from "./outskirt";
import academy from "./academy";
import dashboard from "./dashboard";
import lottery from "./lottery";
import error from "./error";
import staking from "./staking";
import fusion from "./fusion";

const en = {
  user,
  common,
  battle,
  city,
  hero,
  outskirt,
  academy,
  dashboard,
  lottery,
  error,
  staking,
  fusion,
};

export default en;
