import { Box } from "@mui/material";
import ConnectWallet from "../user/ConnectWallet";
import { Link as RouterLink } from "react-router-dom";
import Image from "@components/shared/Image";
import Path from "@routes/path";

const AppBar = () => {
  return (
    <Box display="flex">
      <RouterLink to={Path.Home.root}>
        <Image src={"images/small_logo.png"} alt="logo" width={150} />
      </RouterLink>
      <Box flex={1} />
      <Box>
        <ConnectWallet />
      </Box>
    </Box>
  );
};
export default AppBar;
