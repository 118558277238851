import React from "react";
import AppBar from "../navigation/AppBar";
import Menu from "../navigation/Menu";
import { Box, styled } from "@mui/material";
import { keyframes } from "@mui/styled-engine";

const contentZIndex = 2;
const BaseBox = styled(Box)((theme) => ({
  background: "#587C70",
  backgroundImage: `url("./images/mooning.png"), linear-gradient(to bottom right, #587C70, #000000)`,
  backgroundAttachment: "fixed",
  height: "100vh",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const Video = styled("video")({
  objectFit: "cover",
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 0,
  transition: "0.1s ease-out",
});
const fading = keyframes`
0% {
  opacity: 0.15;
}
50% {
  opacity: 0.6;
}
75% {
  opacity: 0.4;
}

100% {
  opacity: 0.15;
}
`;

const Image = styled("div")((theme) => ({
  animation: `${fading} 3000ms ease-out infinite`,
  backgroundImage: `url("./images/ttk-bg.png")`,
  backgroundAttachment: "fixed",
  backgroundSize: "cover",
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 0,
  transition: "0.1s ease-out",
}));

const videoProps = {
  fire: {
    src: `${process.env.PUBLIC_URL}/videos/fire.mp4`,
    opacity: 0.2,
    playbackRate: 0.25,
  },
};
const BaseVideo = ({
  video,
}: {
  video: { src: string; opacity: number; playbackRate: number };
}) => {
  const { playbackRate, opacity, src } = video;
  const videoRef = React.useRef(null);
  const updatePlaybackRate = React.useCallback(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  return (
    <Video
      playsInline
      autoPlay
      muted
      loop
      ref={videoRef}
      onCanPlay={updatePlaybackRate}
      sx={{ opacity }}
      key={src}
    >
      <source src={src} type="video/mp4" />
    </Video>
  );
};
const Base: React.FC<{
  videoType?: "trailer" | "trailer-bg" | "fire";
}> = ({ videoType, children }) => {
  const video = React.useMemo(
    () => videoType && videoProps[videoType],
    [videoType]
  );

  return (
    <BaseBox>
      <Box px={{ xs: 2, sm: 4 }} pt={{ xs: 2, sm: 4 }} zIndex={contentZIndex}>
        <AppBar />
        <Box mt={2}>
          <Menu />
        </Box>
      </Box>

      <Box
        zIndex={contentZIndex}
        flex={1}
        display="flex"
        flexDirection="column"
        overflow="auto"
        pb={10}
      >
        {children}
      </Box>

      {video ? <BaseVideo video={video} /> : <Image />}
    </BaseBox>
  );
};
export default Base;
