import { useConnect } from "../../contexts/ConnectContext";
import Button from "@components/shared/Button";
import UserAddress from "@components/shared/UserAddress";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

const ConnectWallet = () => {
  const { isConnected, disconnect, connect, user } = useConnect();
  const { t } = useTranslation();

  if (isConnected && !user) {
    return null;
  }
  if (isConnected && user) {
    return (
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <UserAddress user={user} />
        <Button onClick={disconnect} role="button" aria-label="disconnect">
          {t("user:connect_wallet.disconnect")}
        </Button>
      </Box>
    );
  }
  return (
    <Button
      onClick={() => connect()}
      role="button"
      aria-label="connect"
      sx={{ height: 80, width: 200 }}
    >
      {t("user:connect_wallet.connect")}
    </Button>
  );
};
export default ConnectWallet;
