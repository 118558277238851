const battle = {
  "list_battles.title": "Battles",
  "battle.start_new": "New Battle",
  "battlefield.enter": "Enter Battlefield",
  "battlefield.title": "Battlefield - {{name}}",
  "status.ended": "Battle Ended",
  "battlelog.is_offending.attack": "Attack",
  "battlelog.is_offending.defend": "Defend",
  "overvivew.status.attacking": "Attacking",
  "overvivew.status.defending": "Defending",
  "preview.slots.unfilled": "Please select heroes",
  "preview.enter_battle": "Enter Battle",
  "preview.opponent.title": "Opponent",
  "preview.self.title": "You",
  "preview.select_city.title": "Select City",
  "slot.hero.details.title": "Details",
  "slot.hero.select": "Select",

  "decision.title": "Congratulations. Make decisions now:",
  "decision.confirm": "Confirm",
  "decision.success.description": "Successfully made the decision",
  "decision.success.ttk_pocketed": "TTK pocketed: {{ttk}}",
  "decision.persuade": "Persuade",
  "decision.execute": "Execute",
};
export default battle;
