import React from "react";

const useFeatures = () => {
  return React.useMemo(() => {
    return {
      outskirt: process.env.REACT_APP_ENABLE_OUTSKIRT,
    };
  }, []);
};
export default useFeatures;
